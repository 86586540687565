import React, { useEffect } from 'react';
import { Notification, Heading } from 'shared-library';

import { formatCurrency } from 'packages/formatters';

import { useRouteMatch, useLocation } from 'react-router-dom';
import { SuppressEmailOrInvoice } from 'packages/booking-components';
import { useToasts } from 'react-toast-notifications';
import { LineItem } from '../Layout';

export const InvoiceSummary = ({
  studyContext,
  paymentContext,
  piiContextInfo,
  bookingContext
}) => {
  const { addToast } = useToasts();
  const { piiContext } = piiContextInfo;
  const { isTemporaryHold } = bookingContext;
  const { invoiceSummary, total, price, load } = paymentContext;

  const {
    selectedLocation,
    skuDetails,
    invoiceAvailable,
    customerEmailAvailable,
    updateStudyObj,
    updateStudyContext,
    studyId,
    status,
    skuID,
    isUpdateAppointment,
    setIsUpdateAppointment,
    appointmentTime,
    machineID,
    updateAppointment,
    showWhiteLabelToast,
    setShowWhiteLabelToast,
    addTemporaryHoldToGoogleCalendar,
  } = studyContext;

  const {
    discountPromo,
    discountReferral,
    discountReferralCredit,
    paid,
    referralCreditUse,
    serviceCreditUse,
    reconciliationAdjust,
    totalBalance,
  } = invoiceSummary;

  const disablePromoButton = isUpdateAppointment || !machineID || !appointmentTime;

  const { path } = useRouteMatch();
  const { search } = useLocation();
  const hasBooking: boolean = path.includes('/admin/booking')
  const finalTotal = hasBooking ? total : totalBalance;
  const setItemPrice = status.booked || hasBooking;

  const { name: skuName } = skuDetails;
  const skuLabel = setItemPrice ? `${skuName} (${skuID})` : '-';
  const discounts = Number(discountPromo + discountReferral);
  const discountsReferralCredit = Number(discountReferralCredit);
  const credits = Number(serviceCreditUse + referralCreditUse);
  const currency = selectedLocation?.currency?.currency;

  const suppressCustomerEmails = (value) => {
    const updateEmailStatus = {
      [value === 'no-suppress-email' ? 'status' : 'removeStatus']: 'customerEmailAvailable',
    };
    updateStudyObj(updateEmailStatus);
  };
  const suppressInvoiceFunction = (value) => {
    const updateInvoiceStatus = {
      [value === 'no-suppress-invoice' ? 'status' : 'removeStatus']: 'invoiceAvailable',
    };
    updateStudyObj(updateInvoiceStatus);
    updateStudyContext({ invoiceAvailable: value === 'no-suppress-invoice' });
  };

  const handleTemporaryHoldUpdated = () => {
    addToast(
      Notification.create(
        'Temporary Hold has been updated',
        'Temporary hold updated for this scan',
      ),
      { appearance: 'success' },
    );
  };

  /**
   * Update study upon location, sku, appointmentTime or machineId changes to get the updated invoice summary
   * and allow the discount can be properly validated in the backend
   */
  useEffect(() => {
    const params = new URLSearchParams(search);
    const personId = params.get('personID');
    const opportunityId = params.get('opportunityID');

    const onStudyChange = async () => {
      try {
        setIsUpdateAppointment(true);
        await updateAppointment();

        if (isTemporaryHold) {
          await addTemporaryHoldToGoogleCalendar(handleTemporaryHoldUpdated, piiContext, studyId);
        }
        await load();
      } catch (error) {
        throw new Error(error.message);
      } finally {
        setIsUpdateAppointment(false);
      }
    };

    const isAllowedDomain = ![
      '/admin/study/:studyId/details',
      '/admin/booking/study/:studyId',
      '/study/:studyId/:tab',
      '/admin/frontdesk/study/:studyId',
    ].includes(path);

    if (isAllowedDomain) {
      if (studyId && appointmentTime && machineID && selectedLocation && skuID && !personId && !opportunityId) {
        onStudyChange();
      }
    }
  }, [selectedLocation, skuID, appointmentTime, machineID]);

  useEffect(() => {
    if (studyId && selectedLocation.type === 'whitelabel') {
      load();
    }
  }, [studyId]);

  useEffect(() => {
    if (showWhiteLabelToast) {
      addToast(
        Notification.create(
          'Invoice payment automatically issued',
          'Refresh the page if the payment summary is not updated.',
        ),
        { appearance: 'success' },
      );

      setShowWhiteLabelToast(false);
    }
  }, [showWhiteLabelToast]);

  return (
    <>
      <LineItem
        amount={formatCurrency(price, currency)}
        heavy
        label={setItemPrice ? skuLabel : 'No Items Booked'}
        spaced
      />
      {!disablePromoButton ? (
        <>
          <LineItem data-testid='invoice-amount-id' amount={formatCurrency(discounts, currency)} label="Discounts" />
          <LineItem data-testid='invoice-referral-credits-id' amount={formatCurrency(discountsReferralCredit, currency)} label="Referral Credits" />
          <LineItem data-testid='invoice-credits-id' amount={formatCurrency(credits, currency)} label="Credits" />
          <LineItem data-testid='invoice-paid-id' amount={formatCurrency(paid, currency)} label="Paid" />
          {reconciliationAdjust != null && (
            <LineItem data-testid='invoice-reconciliations-id' amount={formatCurrency(reconciliationAdjust, currency)} label="Reconciliations" spaced />
          )}
          <LineItem data-testid='invoice-balance-id' amount={formatCurrency(finalTotal, currency)} label="Balance" heavy />
        </>
      ) : (
        <Notification appearance="warning" width="100%">
          <Heading size={7} noMargin>
            Please select the appointment location, scan type and date & time first.
          </Heading>
        </Notification>
      )}
      <SuppressEmailOrInvoice
        invoiceAvailable={invoiceAvailable}
        suppressCustomerEmails={suppressCustomerEmails}
        customerEmailAvailable={customerEmailAvailable}
        suppressInvoiceFunction={suppressInvoiceFunction}
        studyId={studyId}
      />
    </>
  );
};

export default InvoiceSummary;
