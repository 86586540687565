import React, { useEffect, useState, useContext } from 'react';

import { useRouteMatch } from 'react-router-dom';
import { StudyContext } from 'packages/booking-contexts';
import { fetchAppsResource, fetchPiiResource } from 'packages/apis';
import { updateSfOpportunity } from 'packages/sales-force';
import { checkIfRequiredQuestionsComplete, getRedux } from 'packages/booking-components';
import { getPatientInformation } from './paymentFunctions';

const DEFAULT_PAYMENT_TYPE = 'stripe';

export const PaymentContext = React.createContext({});

export const PaymentContextProvider = ({ children }) => {
  const { studyId, selectedLocation, skuID, status } = useContext(StudyContext);
  const [paymentType, setPaymentType] = useState(DEFAULT_PAYMENT_TYPE);
  const [transactions, setTransactions] = useState([]);
  const [invoiceSummary, setInvoiceSummary] = useState({
    paid: 0,
    totalPrice: 0,
    discountPromo: 0,
    discountReferral: 0,
    serviceCreditUse: 0,
    referralCreditUse: 0,
    reconciliationAdjust: null,
    totalBalance: 0,
  });
  const [total, setTotal] = useState(0);
  const [price, setPrice] = useState(0);

  const [viewTransaction, setViewTransaction] = useState(null);
  const [showSafetyQuestionsDialog, setShowSafetyQuestionsDialog] = useState(false);
  const [user, setUser] = useState({
    firstname: '',
    lastname: '',
    email: '',
    // eslint-disable-next-line camelcase
    user_id: '',
    roles: [],
  });

  const isOnAdminPayment = window.location.href.includes('admin/study');

  useEffect(() => {
    const newTotal = (
      Number(price || 0) -
      invoiceSummary.paid -
      Math.abs(invoiceSummary.discountPromo) -
      Math.abs(invoiceSummary.discountReferral)
    ).toFixed(2);
    setTotal(newTotal);
  }, [invoiceSummary, price]);

  useEffect(() => {
    if (selectedLocation?.type === 'whitelabel') {
      setPaymentType('invoice');
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (viewTransaction && !viewTransaction.issued && viewTransaction.paymentType === 'invoice') {
      setPaymentType(viewTransaction.paymentType);
    } else {
      setPaymentType(DEFAULT_PAYMENT_TYPE);
    }
  }, [viewTransaction]);

  const { path } = useRouteMatch();

  useEffect(() => {
    if (invoiceSummary?.totalPrice) {
      setPrice(invoiceSummary.totalPrice);
    } else if (selectedLocation?.catalog?.primary && skuID && status) {
      const selectedSku = selectedLocation?.catalog?.primary.find((sku) => sku.skuID === skuID);
      const setItemPrice = status.booked || path.includes('/booking');
      setPrice(setItemPrice ? selectedSku.price : 0);
    }
  }, [invoiceSummary, selectedLocation, skuID, status]);

  const paymentArgChange = (change) => {
    if (change.paymentType) {
      setPaymentType(change.paymentType);
    }
    if (change.transactions) {
      setTransactions(change.transactions);
    }
    if (change.invoiceSummary) {
      setInvoiceSummary({ ...invoiceSummary, ...change.invoiceSummary });
    }
  };

  const load = async (invoiceSummaryOnly = false) => {
    if (studyId) {
      const url = invoiceSummaryOnly
        ? `/payment/study/${studyId}/online-booking`
        : `/payment/study/${studyId}`;
      const { parsedBody: data } = await fetchAppsResource(url);
      paymentArgChange({
        transactions: data.transactions ? data.transactions : [],
        invoiceSummary: {
          totalPrice: data.totalPrice || 0,
          paid: data.paid || 0,
          discountPromo: data.discountPromo || 0,
          discountReferral: data.discountReferral || 0,
          discountReferralCredit: data.discountReferralCredit || 0,
          reconciliationAdjust: data.reconciliationAdjust,
          totalBalance: data.totalBalance || 0,
        },
      });
    }
  };

  const checkIfNeedSafetyQuestions = async (proactiveBooking, prenuvoId) => {
    if (!isOnAdminPayment && proactiveBooking) {
      const checkinState = await getRedux(studyId);
      const piiInformation = await getPatientInformation(prenuvoId);
      const requiredFields = checkIfRequiredQuestionsComplete({
        checkinContext: checkinState,
        piiContext: piiInformation,
      });
      if (requiredFields > 0) {
        setShowSafetyQuestionsDialog(proactiveBooking);
      }
    }
  };

  const recordPayment = async (paymentObj, callback) => {
    try {
      if (!studyId) {
        return 'Please Add Location and Time Before Completing a Payment';
      }
      const { parsedBody: data } = await fetchAppsResource(`/payment/study/${studyId}`, 'POST', {
        ...paymentObj,
        locationID: selectedLocation.locationID,
      });
      if (data?.redirect) {
        const { redirect } = data;
        if (typeof redirect === 'string') {
          window.location.href = redirect;
        }
      }

      if (callback && data?.paidStatus) {
        callback(data?.paidStatus, data?.paidTime);
      }

      if (data?.proactiveBooking) {
        const { parsedBody: study } = await fetchAppsResource(`/study/${studyId}`);
        if (selectedLocation.type !== 'whitelabel') {
          await updateSfOpportunity({
            study: {
              ...study,
              prenuvoId: study?.patient?.prenuvoID || study?.patient,
              stage: study.status.booked,
              appointmentTime: study.booking[0].utcStart,
            },
            newOpportunity: false,
          });
        }
        await checkIfNeedSafetyQuestions(
          data.proactiveBooking,
          study?.patient?.prenuvoID || study?.patient,
        );
      }

      return data;
    } catch ({ response: error }) {
      if (error?.data) {
        return error.data;
      }
      return error;
    }
  };

  const updatePayment = async (paymentObj, callback) => {
    try {
      const { parsedBody: data } = await fetchAppsResource(
        `/payment/study/${studyId}/update/${paymentObj?.transactionID}`,
        'POST',
        paymentObj,
      );
      if (data) {
        const { redirect } = data;
        if (redirect) {
          window.location.href = redirect;
        }
      }

      if (callback && data?.paidStatus) {
        callback(data?.paidStatus, data?.paidTime);
      }
      checkIfNeedSafetyQuestions(data?.proactiveBooking, data?.prenuvoID);

      return data;
    } catch ({ response: error }) {
      if (error?.data) {
        return error.data;
      }
      return error;
    }
  };

  const refund = async (transactionID, refundDetails, onSuccess, onFail) => {
    try {
      const { parsedBody: data } = await fetchAppsResource(
        `/payment/study/${studyId}/refund/${transactionID}`,
        'POST',
        refundDetails,
      );
      if (onSuccess) {
        onSuccess(data);
      }
    } catch (err) {
      if (onFail) {
        onFail(err);
      }
    }
  };

  useEffect(() => {
    load(path === '/booking/:page/:studyId').then();
  }, [studyId]);

  const fetchCurrentUser = async () => {
    const { parsedBody } = await fetchPiiResource('/api/v1/userV2/current');
    const { user: userResponse } = parsedBody;

    setUser(userResponse);
  };

  useEffect(() => {
    fetchCurrentUser().then();
  }, [])

  return (
    <PaymentContext.Provider
      value={{
        user,
        studyId,
        paymentType,
        transactions,
        paymentArgChange,
        load,
        invoiceSummary,
        total,
        price,
        recordPayment,
        updatePayment,
        refund,
        viewTransaction,
        setViewTransaction,
        showSafetyQuestionsDialog,
        setShowSafetyQuestionsDialog
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
